<template>
  <!-- 弹框 -->
  <el-dialog v-loading="loading" class="dialog" width="1400px" title="批量作废" :visible.sync="show" :close-on-click-modal="false" :before-close="onCancel">
    <el-form></el-form>
    <div class="search">
      <div>
        <!-- <el-cascader :options="options" placeholder="筛选分类" v-model="categoryidarr" @change="clearlist" @visible-change="changeid" :props="props" collapse-tags clearable style="margin-right: 20px;"></el-cascader> -->
        <el-cascader class="cascader" v-model="categoryidarr" style="margin-right: 20px" :options="options" :props="{ expandTrigger: 'click', label: 'categoryName', value: 'categoryId', children: 'children', checkStrictly: false, emitPath: false, multiple: true }" collapse-tags @change="onSearch" clearable placeholder="请选择分类"></el-cascader>
        <el-input class="ipt" v-model="productName" placeholder="请输入商品名称" suffix-icon="el-icon-search" @change="onSearch" clearable style="margin-right: 20px"></el-input>
        <el-input class="ipt" v-model="brand" placeholder="请输入品牌" suffix-icon="el-icon-search" @change="onSearch" clearable style="margin-right: 20px"></el-input>
        <el-input class="ipt" v-model="tendersLabel" placeholder="请输入标号" suffix-icon="el-icon-search" @change="onSearch" clearable style="margin-right: 20px"></el-input>
        <el-button type="primary" @click="changeall">全 选</el-button>
      </div>
      <div>
        <!-- <el-cascader :options="options" v-model="Allcategoryidarr" :props="props" @visible-change="changeall" collapse-tags clearable></el-cascader> -->
      </div>
    </div>
    <div class="globle_table">
      <el-table :data="tableData" style="width: 100%" height="540" ref="multipleTable" tooltip-effect="dark" row-key="tenderProductId" @select-all="selectAll" @select="handleSelectionChange">
        <el-table-column type="selection" min-width="55"></el-table-column>
        <el-table-column prop="tendersNumber" label="标号" min-width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="barCode" label="商品条形码" min-width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="productName" label="商品名称" min-width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="brand" label="品牌" min-width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="manufacturer" label="厂家" min-width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="categoryName" label="分类名称" min-width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="specs" label="规格 " min-width="150"></el-table-column>
      </el-table>
    </div>
    <div class="pagebox">
      <el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper,sizes, total" :total="totalItemsCount"></el-pagination>
    </div>
    <span slot="footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" :disabled="btdisabled" @click="onSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.Selectlist = this.productalllist ? this.productalllist : []
        this.productList()
      } else {
        this.brand = ""
        this.tendersLabel = ""
        this.productName = ""
        this.categoryidarr = []
        this.Selectlist = []
        this.productalllist = []
        this.tableData = []
      }
    },
  },
  data() {
    return {
      loading: false,
      brand: "",
      tendersLabel: "",
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0,
      categoryName: "",
      categoryId: "",
      categoryidarr: [],
      Allcategoryidarr: [],
      tableData: [],
      productName: "",
      productalllist: [],
      props: {
        checkStrictly: true,
        value: "categoryId",
        label: "categoryName",
        children: "children",
      },
      options: [],
      Selectlist: [],
      btdisabled: false,
    }
  },
  created() {
    this.getcategoryList()
  },
  mounted() {},
  methods: {
    handleSelectionChange(arr, row) {
      const bool = this.Selectlist.some(v => v.tenderProductId === row.tenderProductId)
      if (bool) {
        this.Selectlist = this.Selectlist.filter(v => v.tenderProductId !== row.tenderProductId)
      } else {
        this.Selectlist.push(row)
      }
    },
    selectAll(arr) {
      if (arr.length !== 0) {
        arr.forEach(item => {
          const bool = this.Selectlist.some(v => v.tenderProductId === item.tenderProductId)
          if (!bool) {
            this.Selectlist.push(item)
          }
        })
      } else {
        this.tableData.forEach(item => {
          this.Selectlist = this.Selectlist.filter(v => v.tenderProductId !== item.tenderProductId)
        })
      }
    },
    echoSelected() {
      // 回显选中
      if (this.Selectlist.length > 0) {
        this.$nextTick(() => {
          this.tableData.forEach(item => {
            if (this.Selectlist.some(v => v.tenderProductId === item.tenderProductId)) {
              // 存在添加
              this.$refs.multipleTable.toggleRowSelection(item)
            }
          })
        })
      }
    },
    getcategoryList() {
      this.$axios
        .get(this.$api.categoryList, {
          params: {
            page: 1,
            pageSize: 9999,
            auditStatus: 1,
            // categoryName: this.categoryName,
          },
        })
        .then(res => {
          this.options = res.data.result.list
          this.options.map(item => {
            if (item.children.length == 0) {
              item.children = null
            }
          })
        })
    },
    changeid(value) {
      if (!value) {
        this.categoryId = this.categoryidarr.length != 0 ? this.categoryidarr[this.categoryidarr.length - 1] : ""
        this.currentPage = 1
        this.productList()
      }
    },
    clearlist(value) {
      this.currentPage = 1
      this.categoryId = value.length != 0 ? value[value.length - 1] : ""
      if (this.categoryId == "") {
        this.productList()
        return
      }
    },
    // 全选
    changeall() {
      var loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      })

      this.$axios
        .post(this.$api.allPortalPageList, {
          productName: this.productName || null,
          brand: this.brand || null,
          categoryIdList: this.categoryidarr || [],
        })
        .then(res => {
          this.echoSelected() //重置选中
          setTimeout(() => {
            var storelist = this.Selectlist
            var pushlist = storelist.concat(res.data.result)
            let uniqueDataList = pushlist.filter((item, index, self) => {
              const firstIndex = self.findIndex(obj => obj.tenderProductId === item.tenderProductId)
              return index === firstIndex
            })
            this.Selectlist = uniqueDataList
            this.echoSelected()
            loading.close()
            this.$forceUpdate()
          }, 100)
        })
    },
    // 列表
    productList() {
      this.loading = true
      this.$axios
        .post(this.$api.portalPageList_new, {
          page: this.currentPage,
          pageSize: this.pageSize,
          productName: this.productName || null,
          tendersLabel: this.tendersLabel || null,
          brand: this.brand || null,
          categoryIdList: this.categoryidarr || [],
        })
        .then(res => {
          this.tableData = res.data.result.list
          if (res.data.result.list == null) {
            this.tableData = []
          } else {
            this.tableData = res.data.result.list
          }
          this.totalItemsCount = res.data.result.totalCount
          this.echoSelected()
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleCurrentChange(page) {
      this.currentPage = page
      this.productList()
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.productList()
    },
    onSearch() {
      this.currentPage = 1
      this.productList()
    },
    onCancel() {
      this.Selectlist = []
      this.$refs.multipleTable.clearSelection()
      this.echoSelected()
      this.$emit("onCancel")
    },
    onSubmit() {
      var loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      })
      this.btdisabled = true
      this.Selectlist = this.Selectlist.map(v => {
        return {
          ...v,
          //   depositAmount: v.depositAmount ? v.depositAmount : 0,
        }
      })
      this.$refs.multipleTable.clearSelection()
      setTimeout(() => {
        this.btdisabled = false
        loading.close()
        this.$emit("onSubmit", this.Selectlist)
      }, 1000)
    },
    handleDel() {},
  },
}
</script>

<style lang="scss" scoped>
.dialog {
  margin-top: 0;
  max-height: 100vh;
  ::v-deep .el-dialog__body {
    max-height: 800px;
  }
}
.pagebox {
  ::v-deep .el-input,
  ::v-deep .el-input__inner {
    width: 90px;
  }
}

.search {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;

  ::v-deep .el-input {
    width: 255px;
  }
  .ipt {
    width: 200px;
    ::v-deep .el-input__inner {
      width: 200px;
    }
  }

  .cascader {
    width: 300px;
    ::v-deep .el-input,
    ::v-deep .el-input__inner {
      width: 300px;
    }
  }
}
</style>
